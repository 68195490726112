import React, { Fragment } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { useIdentityContext } from 'react-netlify-identity'

import { Menu, MenuItem, Link, ListItemIcon, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MenuIcon from '@mui/icons-material/Menu'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LanguageIcon from '@mui/icons-material/Language'
import GoogleIcon from '@mui/icons-material/Google'

import logo from '../../assets/joywallet-logo.png'

export default function () {
	const { logoutUser } = useIdentityContext()
	let history = useHistory()

	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleLogout = async () => {
		setAnchorEl(null)
		await logoutUser()
		history.push('/login')
	}

	return (
		<header>
			<Link to="/" component={RouterLink}>
				<img src={logo} className="logo" alt="logo" />
			</Link>

			<div className="right">
				<Tooltip title="Navigation Menu">
					<IconButton variant="outlined" onClick={e => setAnchorEl(e.currentTarget)} aria-controls="long-menu">
						<MenuIcon />
					</IconButton>
				</Tooltip>

				<Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={() => setAnchorEl(null)}>
					<MenuItem component={RouterLink} to="/" color="primary" onClick={() => setAnchorEl(null)}>
						<ListItemIcon>
							<GoogleIcon />
						</ListItemIcon>
						CrUX
					</MenuItem>

					<MenuItem component={RouterLink} to="/wpt" onClick={() => setAnchorEl(null)}>
						<ListItemIcon>
							<LanguageIcon />
						</ListItemIcon>
						WebPageTest
					</MenuItem>

					<MenuItem onClick={handleLogout}>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						Logout
					</MenuItem>
				</Menu>
			</div>
		</header>
	)
}
