import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'
import { GoogleSpreadsheet } from 'google-spreadsheet'

import { GoogleSheetId, GoogleSheetName } from '../utils/constants'
import { convertUrlToSlug } from '../utils/slug-helper'

let KEY = null

export const setKey = key => {
	KEY = key
}

export const loadData = async key => {
	try {
		const doc = new GoogleSpreadsheet(GoogleSheetId)
		await doc.useServiceAccountAuth(key)
		await doc.loadInfo()

		const sheet = doc.sheetsByTitle[GoogleSheetName]
		const rows = await sheet.getRows()

		const mapped = rows.map(r => ({
			id: nanoid(),
			date: r['Date'],
			dateFormatted: DateTime.fromISO(r['Date']).toFormat('dd-LLL'),
			dateObj: DateTime.fromISO(r['Date']),
			time: r['Time'],
			type: r['Type'],
			url: r['URL'],
			slug: convertUrlToSlug(r['URL']),
			device: r['Device'],

			lcp_good: toNum(r['LCP Good']),
			lcp_ni: toNum(r['LCP Needs Improvement']),
			lcp_poor: toNum(r['LCP Poor']),
			lcp_P75: toNum(r['LCP P75']),

			fcp_good: toNum(r['FCP Good']),
			fcp_ni: toNum(r['FCP Needs Improvement']),
			fcp_poor: toNum(r['FCP Poor']),
			fcp_P75: toNum(r['FCP P75']),

			fid_good: toNum(r['FID Good']),
			fid_ni: toNum(r['FID Needs Improvement']),
			fid_poor: toNum(r['FID Poor']),
			fid_P75: toNum(r['FID P75']),

			cls_good: toNum(r['CLS Good']),
			cls_ni: toNum(r['CLS Needs Improvement']),
			cls_poor: toNum(r['CLS Poor']),
			cls_P75: toNum(r['CLS P75']),
		}))

		return mapped
	} catch (err) {
		console.error(err)
	}
}

const toNum = str => (str ? parseFloat(str) : null)
