import axios from 'axios'
import { DateTime } from 'luxon'
import { convertUrlToSlug } from '../utils/slug-helper'

export const get = async (token, filter) => {
	const obj = {
		type: 'get',
		...filter,
	}

	try {
		const options = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
		const response = await axios.post('/.netlify/functions/mongo', obj, options)

		if (response && response.data) {
			const items = response.data
			return items.map(i => ({
				id: i._id,
				timestamp: i['timestamp'],
				dateObj: DateTime.fromSeconds(i['timestamp'] ? +i['timestamp'] : null),
				wptId: i['id'],
				url: i['url'],
				slug: convertUrlToSlug(i['url']),

				device: i['device'],
				lcp: i['Largest Contentful Paint (ms)'],
				tbt: i['Total Blocking Time (ms)'],
				cls: i['Cumulative Layout Shift (n)'],
			}))
		}
	} catch (error) {
		console.error('Error while sending to Netlify', error.message)
	}
}

export const getPages = async token => {
	const obj = {
		type: 'get-pages',
	}

	try {
		const options = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
		const response = await axios.post('/.netlify/functions/mongo', obj, options)

		if (response && response.data) {
			const items = response.data

			return items.sort().map(i => ({
				url: i,
				slug: convertUrlToSlug(i),
			}))
		}
	} catch (error) {
		console.error('Error while sending to Netlify', error.message)
	}
}
