import React, { useEffect, useState } from 'react'
import { useIdentityContext } from 'react-netlify-identity'
import { get, getPages } from '../services/mongo'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Button, CircularProgress, Link, ListSubheader } from '@mui/material'
import TextField from '@mui/material/TextField'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'

import { DateTime } from 'luxon'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import ComputerIcon from '@mui/icons-material/Computer'
import DevicesIcon from '@mui/icons-material/Devices'
import ErrorIcon from '@mui/icons-material/Error'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import CruxGrid from './CruxGrid'

import { convertUrlToSlug } from '../utils/slug-helper'
import WptGrid from './WptGrid'

export default function WptDashboard() {
	const [items, setItems] = useState([])
	const [dataLoading, setDataLoading] = useState(false)

	const identity = useIdentityContext()
	const [token] = useState(identity?.user?.token?.access_token)

	const [urls, setUrls] = useState([])
	const [selectedUrl, setSelectedUrl] = useState('')
	const [selectedDevice, setSelectedDevice] = useState('')
	const [data, setData] = useState([])
	const [selectedStartDate, setSelectedStartDate] = useState(DateTime.now().minus({ weeks: 1 }))
	const [selectedEndDate, setSelectedEndDate] = useState(DateTime.now())
	const [filtered, setFiltered] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [urlsWithIssues, setUrlsWithIssues] = useState([])

	useEffect(() => {
		;(async function () {
			if (token) {
				await fetchData(token)
			}
		})()
	}, [token, selectedStartDate, selectedEndDate, selectedDevice, selectedUrl])

	useEffect(() => {
		;(async function () {
			if (token) {
				await fetchUrls(token)
			}
		})()
	}, [token])

	const fetchUrls = async token => {
		try {
			setUrls([])

			const items = await getPages(token)

			setUrls(items)
		} catch (err) {
			console.error(err)
			alert('Error while loading data.')
		}
	}

	const fetchData = async token => {
		try {
			setItems([])
			setDataLoading(true)

			const items = await get(token, {
				from: selectedStartDate.toISODate(),
				to: selectedEndDate.toISODate(),
				url: selectedUrl,
				device: selectedDevice,
			})

			setItems(items)
			setDataLoading(false)
		} catch (err) {
			console.error(err)
			setDataLoading(false)
			alert('Error while loading data.')
		}
	}

	return (
		<div>
			<div className="filter-row">
				<DesktopDatePicker
					margin="normal"
					label="From"
					inputFormat="MM/dd/yyyy"
					value={selectedStartDate}
					onChange={setSelectedStartDate}
					renderInput={params => <TextField className="control min-width margin" {...params} />}
				/>

				<DesktopDatePicker
					margin="normal"
					label="To"
					inputFormat="MM/dd/yyyy"
					value={selectedEndDate}
					onChange={setSelectedEndDate}
					renderInput={params => <TextField className="control min-width margin" {...params} />}
				/>

				<FormControl className="control min-width margin">
					<InputLabel id="page-select-label">Page</InputLabel>

					<Select labelId="page-select-label" id="page-select" value={selectedUrl} onChange={e => setSelectedUrl(e.target.value)}>
						<MenuItem key="all" value="">
							All
						</MenuItem>
						{urls.map(item => (
							<MenuItem key={item.url} value={item.url}>
								{item.slug}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className="control margin">
					<ToggleButtonGroup value={selectedDevice} exclusive onChange={(e, val) => val && setSelectedDevice(val)} size="large">
						<ToggleButton value="">
							<Tooltip title="All">
								<DevicesIcon />
							</Tooltip>
						</ToggleButton>

						<ToggleButton value="mobile">
							<Tooltip title="Mobile">
								<PhoneIphoneIcon />
							</Tooltip>
						</ToggleButton>
						<ToggleButton value="desktop">
							<Tooltip title="Desktop">
								<ComputerIcon />
							</Tooltip>
						</ToggleButton>
					</ToggleButtonGroup>
				</FormControl>
			</div>

			<WptGrid data={items} loading={dataLoading} />
		</div>
	)
}
