import { Link } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import { formatDate } from '../utils/date-helper'

const formatMetric = ({ value }) => <span className={`${value >= 75 ? 'good' : 'poor'}`}>{value}</span>

const formatPercentile = (value, target) => <span className={`${value < target ? 'good' : 'poor'}`}>{value}</span>

const columns = [
	{
		field: 'id',
		headerName: 'id',
		hide: true,
	},
	{
		field: 'dateObj',
		headerName: 'Date',
		type: 'date',
		width: 120,
		valueFormatter: ({ value }) => formatDate(value),
	},
	{
		field: 'slug',
		headerName: 'URL',
		flex: 1,
		renderCell: ({ row }) => {
			return (
				<Link className="MuiDataGrid-cell" href={row.url} target="_blank">
					{row.slug}
				</Link>
			)
		},
	},
	{
		field: 'device',
		headerName: 'Device',
		valueFormatter: ({ value }) => {
			if (value === 'ALL_FORM_FACTORS') return 'All'
			else if (value === 'PHONE') return 'Mobile'
			else if (value === 'DESKTOP') return 'Desktop'
		},
	},
	{ field: 'lcp_good', headerName: 'LCP Good (%)', width: 130, renderCell: formatMetric },
	{ field: 'fid_good', headerName: 'FID Good (%)', width: 130, renderCell: formatMetric },
	{ field: 'cls_good', headerName: 'CLS Good (%)', width: 130, renderCell: formatMetric },
	{ field: 'lcp_P75', headerName: 'LCP 75th P', width: 130, renderCell: ({ value }) => formatPercentile(value, 2500) },
	{ field: 'fid_P75', headerName: 'FID 75th P', width: 130, renderCell: ({ value }) => formatPercentile(value, 100) },
	{ field: 'cls_P75', headerName: 'CLS 75th P', width: 130, renderCell: ({ value }) => formatPercentile(value, 0.1) },
]

export default function CruxGrid({ data }) {
	const [sortModel, setSortModel] = useState([
		{
			field: 'dateObj',
			sort: 'desc',
		},
	])
	return (
		<div style={{ display: 'flex', height: '100%' }}>
			<div style={{ flexGrow: 1 }}>
				<DataGrid
					rows={data}
					columns={columns}
					pageSize={100}
					autoHeight
					disableSelectionOnClick
					sortModel={sortModel}
					onSortModelChange={model => setSortModel(model)}
				/>
			</div>
		</div>
	)
}
