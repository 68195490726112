import { useEffect, useState, useContext } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Button, CircularProgress, Link, ListSubheader } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import BarChartIcon from '@mui/icons-material/BarChart'
import TableChartIcon from '@mui/icons-material/TableChart'
import TextField from '@mui/material/TextField'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'

import uniqby from 'lodash.uniqby'
import groupBy from 'lodash.groupby'
import maxBy from 'lodash.maxby'

import { DateTime } from 'luxon'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import ComputerIcon from '@mui/icons-material/Computer'
import DevicesIcon from '@mui/icons-material/Devices'
import ErrorIcon from '@mui/icons-material/Error'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { loadData } from '../services/google-sheets'
import Chart from './Chart'

import CruxGrid from './CruxGrid'
import ReferenceScoresDialog from './ReferenceScoresDialog'
import { convertUrlToSlug } from '../utils/slug-helper'
import KeysContext from '../context/keys/KeysContext'

export default function Dashboard() {
	const { googleSheetKey, loadKeys } = useContext(KeysContext)

	const [urls, setUrls] = useState([])
	const [selectedUrl, setSelectedUrl] = useState('')
	const [selectedDevice, setSelectedDevice] = useState('ALL_FORM_FACTORS')
	const [data, setData] = useState([])
	const [selectedStartDate, setSelectedStartDate] = useState(DateTime.now().minus({ weeks: 1 }))
	const [selectedEndDate, setSelectedEndDate] = useState(DateTime.now())
	const [filtered, setFiltered] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [urlsWithIssues, setUrlsWithIssues] = useState([])

	const [selectedTab, setSelectedTab] = useState('1')

	useEffect(() => {
		loadKeys()
	}, [])

	useEffect(() => {
		if (!googleSheetKey) return

		const useEffectAsync = async () => {
			const rows = await loadData(googleSheetKey)

			if (rows) {
				setData(rows)

				setSelectedUrl('https://joywallet.com')

				const formattedUrls = formatRecentPages(rows)
				setUrls(formattedUrls)

				setIsLoading(false)
			}
		}

		useEffectAsync()
	}, [googleSheetKey])

	const formatRecentPages = rows => {
		let pageWithDate = []

		//group by the URL to get a unique list of each URL adn calculate the max date of each URL
		const groups = groupBy(rows, 'url')

		Object.keys(groups).forEach(key => {
			pageWithDate.push({
				page: key,
				maxDate: maxBy(groups[key], o => o.dateObj)['date'],
				dateObj: groups[key]['dateObj'],
			})
		})

		//filter the empty strigns to remove the headers
		pageWithDate = pageWithDate.filter(p => p.page.length > 0)

		//group by the date
		let dateWithPages = groupBy(pageWithDate, 'maxDate')

		let dates = []

		Object.keys(dateWithPages).forEach(key => {
			dates.push({
				date: key,
				urls: dateWithPages[key].map(p => p.page).sort(),
			})
		})

		dates.sort((a, b) => new Date(b.date) - new Date(a.date))

		dates = dates.map(({ date, urls }) => {
			const [y, m, d] = date.split('-')

			const formattedUrls = urls.map(u => {
				let text = convertUrlToSlug(u)
				return { url: u, text }
			})

			return {
				urls: formattedUrls,
				date: `${m}/${d}/${y}`,
			}
		})

		return dates
	}

	//Setting the filtered array which is used to populate the graphs
	useEffect(() => {
		let _filtered = data
		if (selectedUrl != '') _filtered = _filtered.filter(r => r.url === selectedUrl)

		_filtered = _filtered.filter(r => r.device === selectedDevice).filter(r => r.dateObj >= selectedStartDate && r.dateObj <= selectedEndDate)

		// const uniqDate = uniqby(_filtered, 'date')
		setFiltered(_filtered)
	}, [selectedUrl, selectedDevice, selectedStartDate, selectedEndDate])

	//Creating a list of URLs with issue to display an error icon at the Pages dropdown
	useEffect(() => {
		const _filtered = data
			.filter(r => r.device === selectedDevice)
			.filter(r => r.dateObj >= selectedStartDate && r.dateObj <= selectedEndDate)
			.filter(r => r.cls_P75 >= 0.1 || r.fid_P75 >= 100 || r.lcp_P75 >= 2500)

		const _urlsWithIssues = uniqby(_filtered, 'url').map(r => r.url)
		setUrlsWithIssues(_urlsWithIssues)
	}, [data, selectedStartDate, selectedEndDate, selectedDevice])

	return (
		<>
			{isLoading && (
				<div className="loading-container">
					<CircularProgress />
				</div>
			)}

			{!isLoading && (
				<div>
					<div className="filter-row">
						<DesktopDatePicker
							margin="normal"
							label="From"
							inputFormat="MM/dd/yyyy"
							value={selectedStartDate}
							onChange={setSelectedStartDate}
							renderInput={params => <TextField className="control min-width margin" {...params} />}
						/>

						<DesktopDatePicker
							margin="normal"
							label="To"
							inputFormat="MM/dd/yyyy"
							value={selectedEndDate}
							onChange={setSelectedEndDate}
							renderInput={params => <TextField className="control min-width margin" {...params} />}
						/>

						<FormControl className="control min-width margin">
							<InputLabel id="page-select-label">Page</InputLabel>

							<Select labelId="page-select-label" id="page-select" value={selectedUrl} onChange={e => setSelectedUrl(e.target.value)}>
								<MenuItem key="all" value="">
									All
								</MenuItem>

								{urls &&
									urls.map(group => [
										<ListSubheader>{group.date}</ListSubheader>,

										group.urls.map(({ url, text }) => (
											<MenuItem key={url} value={url}>
												<span style={{ display: 'flex', alignItems: 'center' }}>
													{text}
													{urlsWithIssues.includes(url) ? <ErrorIcon style={{ color: '#d93025', marginLeft: '10px' }} color="red" /> : ''}
												</span>
											</MenuItem>
										)),
									])}
							</Select>
						</FormControl>

						<FormControl className="control margin">
							<ToggleButtonGroup value={selectedDevice} exclusive onChange={(e, val) => val && setSelectedDevice(val)} size="large">
								<ToggleButton value="ALL_FORM_FACTORS">
									<Tooltip title="All">
										<DevicesIcon />
									</Tooltip>
								</ToggleButton>

								<ToggleButton value="PHONE">
									<Tooltip title="Mobile">
										<PhoneIphoneIcon />
									</Tooltip>
								</ToggleButton>
								<ToggleButton value="DESKTOP">
									<Tooltip title="Desktop">
										<ComputerIcon />
									</Tooltip>
								</ToggleButton>
							</ToggleButtonGroup>
						</FormControl>

						<FormControl className="control last">
							<Button variant="outline" startIcon={<OpenInNewIcon />} component={Link} href={selectedUrl} target="_blank">
								Open
							</Button>

							<ReferenceScoresDialog />
						</FormControl>
					</div>

					<TabContext value={selectedTab}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={(e, val) => setSelectedTab(val)}>
								<Tab icon={<BarChartIcon />} label="graph" value="1" />
								<Tab icon={<TableChartIcon />} label="tabular" value="2" />
							</TabList>
						</Box>
						<TabPanel value="1">
							<div className="charts-row">
								<Chart data={filtered} metric="lcp" label="LCP" />
								<Chart data={filtered} metric="fid" label="FID" />
								<Chart data={filtered} metric="cls" label="CLS" />
							</div>
						</TabPanel>
						<TabPanel value="2">
							<CruxGrid data={filtered} />
						</TabPanel>
					</TabContext>
				</div>
			)}
		</>
	)
}
