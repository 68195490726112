import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts'

export default function Chart({ data, metric, label }) {
	let reference = 0
	let domain = []
	if (metric === 'lcp') {
		reference = 2500
		domain = [0, 5000]
	}
	if (metric === 'fid') {
		reference = 100
		domain = [0, 200]
	}
	if (metric === 'cls') {
		reference = 0.1
		domain = [0, 0.2]
	}
	return (
		<div className="chart">
			<h3>{label}</h3>

			{data && (
				<>
					<div className="chart-container">
						<ResponsiveContainer width="100%" height="100%">
							<LineChart data={data}>
								<ReferenceLine y={75} stroke="#00FFFF" />
								<CartesianGrid strokeDasharray="5 10" />
								<XAxis dataKey="dateFormatted" tick={<CustomizedAxisTick />} />
								<YAxis domain={[0, 100]} />
								<Tooltip />

								<Legend wrapperStyle={{ paddingTop: '50px' }} />
								<Line type="monotone" dataKey={`${metric}_good`} stroke="#45CE6A" strokeWidth={2} name="Good" />
								<Line type="monotone" dataKey={`${metric}_ni`} stroke="#F9A402" strokeWidth={2} name="Needs Improvement" />
								<Line type="monotone" dataKey={`${metric}_poor`} stroke="#F54E42" strokeWidth={2} name="Poor" />
							</LineChart>
						</ResponsiveContainer>
					</div>

					<div className="chart-container">
						<ResponsiveContainer width="100%" height="100%">
							<LineChart data={data}>
								<ReferenceLine y={reference} stroke="#00FFFF" />
								<CartesianGrid strokeDasharray="5 10" />
								<XAxis dataKey="dateFormatted" tick={<CustomizedAxisTick />} />
								<YAxis domain={domain} />

								<YAxis />
								<Tooltip />
								<Legend wrapperStyle={{ paddingTop: '50px' }} />
								<Line type="monotone" dataKey={`${metric}_P75`} stroke="#3551C4" strokeWidth={2} name="75th Percentile" />
							</LineChart>
						</ResponsiveContainer>
					</div>
				</>
			)}
		</div>
	)
}

function CustomizedLabel({ x, y, stroke, value }) {
	return (
		<text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
			{value}
		</text>
	)
}

function CustomizedAxisTick({ x, y, stroke, payload }) {
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={13}>
				{payload.value}
			</text>
		</g>
	)
}
