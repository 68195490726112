import { KEYS_SUCCESS } from '../types.js'

export default function KeysReducer(state, action) {
	const { type, payload } = action

	switch (type) {
		case KEYS_SUCCESS:
			sessionStorage.setItem('googleSheetKey', JSON.stringify(payload))
			return { ...state, googleSheetKey: payload }

		default:
			return state
	}
}
