import React from 'react'

import WptDashboard from '../components/WptDashboard'
import Layout from '../components/layout/Layout'

export default function () {
	return (
		<Layout>
			<WptDashboard />
		</Layout>
	)
}
