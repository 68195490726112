export function convertUrlToSlug(url) {
	let text = ''

	if (url === 'https://joywallet.com') text = 'Origin'
	else if (url === 'https://joywallet.com/') text = 'Homepage'
	else text = capitalize(url.replace('https://joywallet.com/article/', '').replace('/', ''))

	return text
}

function capitalize(str) {
	var splitStr = str.toLowerCase().split('-')
	for (var i = 0; i < splitStr.length; i++) {
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
	}
	return splitStr.join(' ')
}
