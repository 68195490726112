import React from 'react'

import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/Info'

export default function () {
	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<div className="helpIcon">
				<Button variant="outline" startIcon={<InfoIcon />} onClick={handleClickOpen}>
					Status Definitions
				</Button>
			</div>

			<Dialog onClose={handleClose} aria-labelledby="statusDefDialog" open={open}>
				<MuiDialogTitle id="statusDefDialog" onClose={handleClose}>
					Status Definitions
				</MuiDialogTitle>
				<MuiDialogContent>
					<table className="ReferenceScores">
						<thead>
							<tr>
								<th></th>
								<th style={{ color: '#188038' }}>Good</th>
								<th style={{ color: '#b06000' }}>Needs improvement</th>
								<th style={{ color: '#d93025' }}>Poor</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>LCP</th>
								<td>&lt;=2.5s</td>
								<td>&lt;=4s</td>
								<td>&gt;4s</td>
							</tr>
							<tr>
								<th>FID</th>
								<td>&lt;=100ms</td>
								<td>&lt;=300ms</td>
								<td>&gt;300ms</td>
							</tr>
							<tr>
								<th>CLS</th>
								<td>&lt;=0.1</td>
								<td>&lt;=0.25</td>
								<td>&gt;0.25</td>
							</tr>
						</tbody>
					</table>
				</MuiDialogContent>
			</Dialog>
		</>
	)
}
