import { Link } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import { formatDate } from '../utils/date-helper'

const formatPercentile = (value, target) => <span className={`${value < target ? 'good' : 'poor'}`}>{value}</span>

const formatCLS = (value, target) => <span className={`${value < target ? 'good' : 'poor'}`}>{value && parseFloat(value).toFixed(2)}</span>

const columns = [
	{
		field: 'id',
		headerName: 'id',
		hide: true,
	},
	{
		field: 'dateObj',
		headerName: 'Date',
		type: 'date',
		width: 120,
		valueFormatter: ({ value }) => formatDate(value),
	},
	{
		field: 'slug',
		headerName: 'URL',
		flex: 1,
		renderCell: ({ row }) => {
			return (
				<Link className="MuiDataGrid-cell" href={row.url} target="_blank">
					{row.slug}
				</Link>
			)
		},
	},
	{
		field: 'device',
		headerName: 'Device',
		width: 140,
		valueFormatter: ({ value }) => {
			if (value === 'mobile') return 'Mobile'
			else if (value === 'desktop') return 'Desktop'
		},
	},

	{ field: 'lcp', headerName: 'LCP', width: 100, renderCell: ({ value }) => formatPercentile(value, 2500) },
	{ field: 'tbt', headerName: 'TBT', width: 100, renderCell: ({ value }) => formatPercentile(value, 500) },
	{ field: 'cls', headerName: 'CLS', width: 100, renderCell: ({ value }) => formatCLS(value, 0.1) },

	{
		field: 'wptId',
		headerName: 'Result URL',
		width: 130,
		renderCell: ({ row }) => {
			return (
				<Link className="MuiDataGrid-cell" href={`https://www.webpagetest.org/result/${row.wptId}/`} target="_blank">
					Link
				</Link>
			)
		},
	},
]

export default function WptGrid({ data, loading }) {
	const [sortModel, setSortModel] = useState([
		{
			field: 'dateObj',
			sort: 'desc',
		},
	])

	return (
		<div style={{ display: 'flex', height: '100%' }}>
			<div style={{ flexGrow: 1 }}>
				<DataGrid
					loading={loading}
					rows={data}
					columns={columns}
					pageSize={100}
					autoHeight
					disableSelectionOnClick
					sortModel={sortModel}
					onSortModelChange={model => setSortModel(model)}
				/>
			</div>
		</div>
	)
}
